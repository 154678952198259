import React from 'react'
import Post from '../../components/Post/LatestPost/latestPost'
import styles from './youMayAlso.module.css'

const youMayAlso = props => {
  // console.log(props)
  return (
    <div>
      <h2 className={styles.header}> Related Posts </h2>
      
       {props.data.edges.map(({ node }) => {
            var filtered = []
            for (var i = 0; i < props.viewData.edges.length; i++) {
              if (
                props.viewData.edges[i].node.id ===
                '/articles/' + node.slug
              ) {
                filtered.push(props.viewData.edges[i].node)
              }
            }
            return (
              <div className={styles.post} key={node.title}>
                <Post viewData={filtered} data={node} />
              </div>
            )
          })}
    </div>
  )
}

export default youMayAlso

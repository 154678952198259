import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { DOMParser } from 'xmldom'
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../components/Layout'
import styles from '../templateStyles/post.module.css'
import WordpressContent from '../components/PostTemplateComponents/wordpressContent'
import YouMayAlso from '../components/PostTemplateComponents/youMayAlso'
import Emojis from '../components/Emojis/emojis'
import SEO from '../components/SEO/SEO'

export const BlogPostTemplate = props => {
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <div className={styles.wordpressContent}>
          <WordpressContent data={props} />
        </div>

        <Emojis id={props.id} />
        <div className={styles.discussion}>
          <DiscussionEmbed
            shortname="lengjai-com"
            config={props.disqusConfig}
          />
        </div>
        <div className={styles.youMayAlso}>
          <YouMayAlso
            data={props.relatedData}
            id={props.id}
            viewData={props.PageViews}
          />
        </div>
      </div>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data
  const { related: relatedPostData } = data
  const { PageViews: PageViews } = data
  var dom = new DOMParser().parseFromString(`<div>${post.title}</div>`)
  var decodedString = dom.childNodes[0].textContent
  const disqusConfig = {
    url: `https://www.lengjai.com/articles/${post.slug}/`,
    identifier: `${post.id}`,
    title: `${post.title}`,
  }

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.acf.excerpt}
        image={post.featured_media.localFile.childImageSharp.fluid.src}
        pathname={post.slug}
        article
      />
      <BlogPostTemplate
        featuredMedia={post.featured_media}
        content={post.content}
        id={post.id}
        categories={post.categories}
        tags={post.tags}
        title={decodedString}
        date={post.date}
        author={post.author.name}
        author_img={post.author.avatar_urls.wordpress_96}
        relatedData={relatedPostData}
        disqusConfig={disqusConfig}
        preface={post.acf.preface}
        photoCaption={post.acf.photocaption}
        PageViews={PageViews}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
    categories {
      name
    }
    author {
      name
    }
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  query BlogPostByID($id: String!, $categories: String!) {
    wordpressPost: wordpressPost(id: { eq: $id }) {
      id
      title
      acf {
        excerpt
        preface
        photocaption
      }
      author {
        name
        avatar_urls {
          wordpress_96
        }
      }
      slug
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
    }
    PageViews: allPageViews {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    related: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $categories } } } }
    ) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
            preface
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import styles from './preface.module.css'

const preface = props => {
  return (
    <div className={styles.container}>
      <h2 className={styles.preface}>{props.preface}</h2>
      <div className={styles.border}></div>
    </div>
  )
}

export default preface

import React, { Component } from 'react'
import Emoji from 'react-emoji-render'
import styles from './emojis.module.css'
import firebase from '../../utils/firebase'
import Spinner from 'react-spinkit'
import Cookies from 'universal-cookie'
class Emojis extends Component {
  _isMounted = false

  state = {
    path: this.props.id + '/emojis',
    loading: true,
    voted: false,
    emoji1: {
      order: 1,
      name: 'onehunnid',
      count: 0,
      countDB: 0,
      countDisplay: 0,
      selected: false,
    },
    emoji2: {
      order: 2,
      name: 'heart',
      count: 0,
      countDB: 0,
      countDisplay: 0,
      selected: false,
    },
    emoji3: {
      order: 3,
      name: 'sunglasses',
      count: 0,
      countDB: 0,
      countDisplay: 0,
      selected: false,
    },
  }

  async writeCookie(input) {
    const cookies = new Cookies()
    var cookie = cookies.get('lengjaiCookie')
    //update the cookie
    for (x in cookie.pages) {
      for (key in cookie.pages[x]) {
        if (key === this.props.id) {    
          //set the voted variable to the input, false is not voted, json if yes                
          cookie.pages[x][key].voted = input
          //set the state of whichever emoji is voted on , can figure this out tomorrow
          this.setState({voted: cookie.pages[x][key].voted}) 
          console.log('i just set the state of voted to ' + cookie.pages[x][key].voted)
          break
        }
      }
    }

    try {
      await cookies.set('lengjaiCookie', JSON.stringify(defaultCookie), {
        path: '/',
        expires: new Date(2020,11,11)
      })
      console.log('just set the current cookies lol')
    } catch (e) {
      console.log(e)
    }
  }

  async setCurrentCookie(cookies, defaultCookie) {
    try {
      await cookies.set('lengjaiCookie', JSON.stringify(defaultCookie), {
        path: '/',
        expires: new Date(2020,11,11)
      })
      console.log('just set the current cookies lol')
    } catch (e) {
      console.log(e)
    }
  }

  componentDidMount() {
    const defaultCookie = {
      pages: [
        {
          '/': {
            id: '/',
            voted: false,
          },
        },
      ],
    }
    const cookies = new Cookies()
    let currentCookie = ''

    this._isMounted = true

    if (this._isMounted) {
      firebase
        .database()
        .ref(this.state.path)
        .once('value', snapshot => {
          let firebaseObject = snapshot.val()
          if (firebaseObject === null) {
            this.setState({
              emoji1: Object.assign({}, this.state.emoji1, {
                countDB: 0,
                count: 0,
                countDisplay: 0,
              }),
              emoji2: Object.assign({}, this.state.emoji2, {
                countDB: 0,
                count: 0,
                countDisplay: 0,
              }),
              emoji3: Object.assign({}, this.state.emoji3, {
                countDB: 0,
                count: 0,
                countDisplay: 0,
              }),
              loading: false,
            })
          } else {
            this.setState({
              emoji1: Object.assign({}, this.state.emoji1, {
                countDB: firebaseObject.onehunnid.count,
                count: firebaseObject.onehunnid.count,
                countDisplay: firebaseObject.onehunnid.count,
              }),
              emoji2: Object.assign({}, this.state.emoji2, {
                countDB: firebaseObject.heart.count,
                count: firebaseObject.heart.count,
                countDisplay: firebaseObject.heart.count,
              }),
              emoji3: Object.assign({}, this.state.emoji3, {
                countDB: firebaseObject.sunglasses.count,
                count: firebaseObject.sunglasses.count,
                countDisplay: firebaseObject.sunglasses.count,
              }),
              loading: false,
            })
          }
        })

      // START COOKIES LOGIC!!!
      // if (cookies.get('lengjaiCookie')) {
      //   currentCookie = cookies.get('lengjaiCookie')
      //   console.log('you have existing cookies lol')
      // } else {
      //   console.log('we dont have cookies so we have to make some')
      //   this.setCurrentCookie(cookies, defaultCookie)
      //   currentCookie = cookies.get('lengjaiCookie')        
      // }

      // //check to see if the page has been visited yet
      // var x
      // var key
      // var visited = false
      

      // for (x in currentCookie.pages) {
      //   for (key in currentCookie.pages[x]) {
      //     if (key === this.props.id) {
      //       visited = true
            
      //       console.log("yeah it's a match")
      //       console.log('huh, you already visited this page')
            
      //       //set the state of voted to the cookie's value            
      //       this.setState({voted: currentCookie.pages[x][key].voted}) 
      //       console.log('i just set the state of voted to ' + currentCookie.pages[x][key].voted)
      //       break
      //     }
      //   }
      // }
      // if (visited === false) {
      //   console.log('youve never visited this page')
      //   let pushObj = {
      //     [this.props.id]: { id: this.props.id, voted: false },
      //   }
      //   currentCookie.pages.push(pushObj)
      //   cookies.set('lengjaiCookie', JSON.stringify(currentCookie), {
      //     path: '/',
      //     expires: new Date(2020,11,11)
      //   })
      //   console.log('added this page to your cookies')
      //   console.log(currentCookie)
      // }
    }
  }

  componentWillUnmount() {
    console.log('unmounting...')
    this._isMounted = false
  }

  //this sets the firebase to the current countDB
  initializeEmoji = () => {
    firebase
      .database()
      .ref(this.state.path)
      .set({
        [this.state.emoji1.name]: {
          count: this.state.emoji1.countDB,
        },
        [this.state.emoji2.name]: {
          count: this.state.emoji2.countDB,
        },
        [this.state.emoji3.name]: {
          count: this.state.emoji3.countDB,
        },
      })
      .then(data => {
        //success callback
        console.log('DB is set to countDB', data)
      })
      .catch(error => {
        //error callback
        console.log('error ', error)
      })
  }

  //this resets the visual stuff and local count state
  resetCount = () => {
    this.setState({
      // loading: true,
      emoji1: Object.assign({}, this.state.emoji1, {
        count: this.state.emoji1.countDB,
        countDisplay: this.state.emoji1.countDB,
        selected: (this.state.emoji1.selected = false),
      }),
      emoji2: Object.assign({}, this.state.emoji2, {
        count: this.state.emoji2.countDB,
        countDisplay: this.state.emoji2.countDB,
        selected: (this.state.emoji2.selected = false),
      }),
      emoji3: Object.assign({}, this.state.emoji3, {
        count: this.state.emoji3.countDB,
        countDisplay: this.state.emoji3.countDB,
        selected: (this.state.emoji3.selected = false),
      }),
    })
  }

  sendEmoji = emoji => {
    let name = 'emoji' + emoji.order

    this.resetCount()
    this.initializeEmoji()

    //trigger a reload to display current count
    this.setState({
      [name]: Object.assign({}, this.state[name], {
        countDisplay: emoji.count + 1,
        selected: !this.state[name].selected,
      }),
    })
    var updates = {}
    updates[emoji.name] = { count: emoji.count + 1 } //count is the field: emoji.count is the value
    firebase
      .database()
      .ref(this.state.path)
      .update(updates)
      .then(data => {
        //success callback
        console.log('succees ', data)
      })
      .catch(error => {
        //error callback
        console.log('error ', error)
        console.log(this.state.emoji1.count)
      })
  }

  changeColor(emoji) {
    let name = 'emoji' + emoji.order
    this.setState({
      [name]: Object.assign({}, this.state[name], {
        selected: !this.state[name].selected,
      }),
    })
  }

  render() {
    let selected1 = this.state.emoji1.selected ? 'clickMeSelected' : 'clickMe'
    let selected2 = this.state.emoji2.selected ? 'clickMeSelected' : 'clickMe'
    let selected3 = this.state.emoji3.selected ? 'clickMeSelected' : 'clickMe'
    
    return (
      <div className={styles.container}>
        <h3>Reactions</h3>

        {this.state.loading ? (
          <Spinner name="circle" />
        ) : (
          <div className={styles.emojiContainer}>
            <div className={styles.oneHunnid}>
              <div
                className={styles[selected1]}
                onClick={() => this.sendEmoji(this.state.emoji1)}
              >
                <Emoji text="💯" />
              </div>
              <div className={styles.counter}>
                {this.state.emoji1.countDisplay}
              </div>
            </div>
            <div className={styles.heart}>
              <div
                className={styles[selected2]}
                onClick={() => this.sendEmoji(this.state.emoji2)}
              >
                <Emoji text="❤️" />
              </div>
              <div className={styles.counter}>
                {this.state.emoji2.countDisplay}
              </div>
            </div>
            <div className={styles.sunglasses}>
              <div
                className={styles[selected3]}
                onClick={() => this.sendEmoji(this.state.emoji3)}
              >
                <Emoji text="😎" />
              </div>
              <div className={styles.counter}>
                {this.state.emoji3.countDisplay}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Emojis

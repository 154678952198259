import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyDedx8AzWes1N2NjwDyOnae-EmgD_9vsQA',
  authDomain: 'lengjai-6c42a.firebaseapp.com',
  databaseURL: 'https://lengjai-6c42a.firebaseio.com',
  projectId: 'lengjai-6c42a',
  storageBucket: 'lengjai-6c42a.appspot.com',
  messagingSenderId: '980059780679'
};

// console.log(config)
//Okay so I know my global environments file works

var fire = firebase.initializeApp(config);
export default fire;
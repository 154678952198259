import React from 'react';
import styles from './photoCaption.module.css'

const photoCaption = (props) => {
    return ( 
        <div className={styles.container}>
            {props.caption}
        </div>
     );
}
 
export default photoCaption;
import React from 'react'
import styles from './titleBlock.module.css'
import { Link } from 'gatsby'

//come on just fucking work!!
const titleBlock = props => {
  return (
    <div className={styles.titleBlock}>
      <span className={styles.category}>
        {props.props.data.categories.map(category => (
          <Link            
            to={`/` + category.name.toLowerCase()}
            className={styles.cat}
            key={`${category.name}`}
          >
            {category.name.toLowerCase()}
          </Link>
        ))}
      </span>
      <h1 className={styles.title}> {props.props.data.title} </h1>
    </div>
  )
}

export default titleBlock

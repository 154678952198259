import React from 'react'
import Img from 'gatsby-image'
import styles from './wordpressContent.module.css'
import TitleBlock from './titleBlock'
import Author from './author'
import Preface from './preface'
import PhotoCaption from '../../components/photoCaption/photoCaption'

const postTop = props => {
  console.log(props)
  return (
    <div className={styles.container}>
      <Img
        fluid={props.data.featuredMedia.localFile.childImageSharp.fluid}
        alt={''}
        className={styles.featuredImage}
      />

      {props.data.photoCaption ? (
        <div className={styles.PhotoCaption}>
          <PhotoCaption caption={props.data.photoCaption}/>
        </div>
      ) : 
      //insert default
      <div className={styles.PhotoCaption}>
          <PhotoCaption caption={'photo by ' + props.data.author}/>
        </div>
      }

      <div className={styles.titleBlock}>
        <TitleBlock props={props} />
      </div>
      <div className={styles.author}>
        <Author
          name={props.data.author}
          date={props.data.date}
          image={props.data.author_img}
        />
      </div>
      {props.data.preface ? (
        <div className={styles.preface}>
          <Preface preface={props.data.preface} />
        </div>
      ) : null}
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: props.data.content }}
      />
    </div>
  )
}

export default postTop

import React from 'react'
import styles from './author.module.css'
import { Link } from 'gatsby'

const author = props => {
  return (
    <div className={styles.container}>
      <img src={props.image} alt="/" className={styles.image} />
      <div className={styles.content}>
        <Link
          className={styles.author}
          to={`/articles/` + props.name.replace(/\s/g, '').toLowerCase()}
        >
          <span className={styles.name}>{props.name}</span>
        </Link>
        <span className={styles.date}>{props.date}</span>
      </div>
    </div>
  )
}

export default author
